import type { Price } from '~/interfaces/Boardgame';
import type { CategoryBoardgame } from '~/interfaces/Category';

export const useCollectDate = () => {
  const { cart } = useCart();
  const toast = useToast();
  const boardgame = useState<CategoryBoardgame>(
    'boardgame-to-collect',
    () => ref({} as CategoryBoardgame)
  );

  const selectPickupPrice = (
    product: CategoryBoardgame,
    selectedPrice: Price
  ) => {
    if (!cart.value.products) {
      cart.value.products = [];
    }

    if (cart.value.products.find((item) => item.id === product.id)) {
      toast.add({ title: 'Este item já está no carrinho', timeout: 2000 });
      return;
    }
    boardgame.value = {
      id: product.id,
      image: product.image,
      name: product.name,
      price_category: product.price_category,
      price: selectedPrice,
    };
  };

  return {
    selectPickupPrice,
    boardgame,
  };
};
